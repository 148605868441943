.tooltipWrapper {
    display: flex;
    justify-content: end;
    width: 100%;
}

.selectableCard.overallTotalCard {
    .cardContent {
        // height: 280px;
    }
}

.selectableCard {
    background-color: #fff;
    box-shadow: 0px 4px 14px 0px rgb(0 0 0 / 6%);
    border-radius: 8px;
    margin-right: 10px;
    display: flex;
    flex-flow: column;
    cursor: pointer;

    &.selected {
        box-shadow: 0px 0px 14px rgba(72, 121, 245, 0.3);
    }

    &:hover {
        box-shadow: 0px 0px 14px rgba(72, 121, 245, 0.3);
    }

    &.overall {
        &.selected {
            box-shadow: 0px 0px 14px #fa7226;
        }

        &:hover {
            box-shadow: 0px 0px 14px #fa7226;
        }
    }

    &:nth-last-child(1) {
        margin-left: 0px;
    }

    .cardHeader {
        padding: 8px 13px 6px;
        border-bottom: 1px solid #f6f6f9;
        color: #444652;

        .title {
            width: 80%;

            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .cardContent {
        width: 100%;
        height: 100%;
    }
}

.disabledCard {
    opacity: 0.61;
}

@keyframes pulseGlow {
    0% {
        opacity: 0.7;
        box-shadow: 0 0 5px rgba(52, 152, 219, 0.5);
    }
    100% {
        opacity: 1;
        box-shadow: 0 0 15px rgba(52, 152, 219, 1);
    }
}

.selectableCard.cardAnimation{    
    color: white;
    box-shadow: 0 0 15px rgba(52, 152, 219, 0.5);
    animation: pulseGlow 1.5s forwards;
}

.selectableCard.skeleton {
    cursor: default;

    &.selected {
        box-shadow: none;
    }

    &:hover {
        box-shadow: none;
    }

    &.overall {
        &.selected {
            box-shadow: none;
        }

        &.hover {
            box-shadow: none;
        }
    }
}