.qsOverlay {
  z-index: 9998;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

.questionSetup {
  .hdr {
    height: 70px;
    padding: 0px 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .addQBtn {
      background-color: #07621f;
      border-radius: 5px;
      color: white;
      padding: 6px 20px;
      cursor: pointer;
    }

    .close {
      background-color: rgba(0, 0, 0, 0.1);
      height: 28px;
      width: 28px;
      border-radius: 50%;
      cursor: pointer;
      transition: all 0.1s;

      &:hover {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
  }

  .addQOverlay {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }

  .addQWrpr {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 16px 0px rgb(0 0 0 / 8%);
    z-index: 2;
    width: 470px;

    input {
      display: block;
      width: 100%;
      height: 40px;
      border-radius: 4px;
      background: none;
      border: 1px solid #e4e4e4;
      padding: 0px 16px;
    }

    .ftr {
      button {
        padding: 10px 30px;
        border-radius: 4px;
        border-color: transparent;
        letter-spacing: 1px;
        cursor: pointer;
        transition: all 0.2s;

        &.text {
          font-weight: 600;
          border-color: transparent;
          background: none;
          color: rgba(0, 0, 0, 0.7);
        }

        &:hover {
          background-color: rgba(7, 98, 31, 0.08);
        }
      }
    }
  }

  .content {
    height: 100%;
    overflow: hidden;

    .tabsWrpr {
      margin-top: 10px;
      margin-bottom: 10px;

      .tab {
        cursor: pointer;
        background-color: transparent;
        transition: all 0.3s;
        border: 1px solid #e8e8eb;
        border-radius: 8px;
        margin-right: 8px;
        padding: 0px 22px;
        height: 42px;
        color: #636578;

        &.active {
          background-color: #deeeff;
          color: #4879f5;
          border-color: #4879f5;
        }
      }
    }

    .questionList {
      height: calc(100% - 200px);
      padding-bottom: 50px;
      padding: 10px 20px 50px 20px;
      overflow: auto;
      .line {
        height: 60px;
        background-color: white;
        padding: 0px 20px;
        margin-bottom: 4px;
        opacity: 1;
        transition: all 0.2s;
        border-radius: 6px;
        border: 1px solid #e4e4e4;

        .iconWrpr {
          overflow: hidden;
          width: 0px;
          transition: all 0.2s;
          padding-left: 1px;
        }

        &:hover {
          .iconWrpr {
            overflow: hidden;
            width: 24px;
          }
        }

        &.dragging {
          opacity: 0.4;
          background-color: wheat;
        }

        button.action {
          background-color: transparent;
          border: none;
          height: 30px;
          width: 30px;
          border-radius: 4px;
          transition: all 0.3s;

          &:hover {
            background-color: rgba(0, 0, 0, 0.1);
          }
        }

        .num {
          margin-right: 8px;
        }

        .confirmDelete {
          z-index: 1;
          position: absolute;
          bottom: 0px;
          transform: translateY(100%);
          background-color: white;
          right: 0px;
          padding: 14px;
          outline: none;
          border: 1px solid #b1b1b1;
          border-radius: 4px;

          button {
            border: none;
            border-radius: 4px;
            transition: all 0.3s;
            padding: 10px 26px;
            cursor: pointer;

            &:hover {
              background-color: rgba(0, 0, 0, 0.1);
            }

            &.confirmDeleteBtn {
              // Set background color to warning color
              background-color: #e21515;
              color: white;

              &:hover {
                background-color: #f53b3b;
              }
            }
          }
        }
      }
    }
  }
}
