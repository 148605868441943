/* styles.css */
/* ... other styles */

.lds-ellipsis.small-dots {
    position: absolute;    
    top: 50%;
    left: -15px;
    transform: translateY(-50%);
    width: 25px;
    height: 9px;
}

.lds-ellipsis.small-dots.small-dots-hospital { /* No nesting! */    
    left: 9px;
    top: 60%;
}

.lds-ellipsis.small-dots.small-dots-nineteen {
    left: -5px; 
    top: 60%;
}
.lds-ellipsis.small-dots.small-dots-left {
    left: -5px;    
}
.lds-ellipsis.small-dots.small-dots-admission-dx{
    left: -2px;    
    top: 50%;
}
.lds-ellipsis.small-dots.small-dots-user-list{
    left: 10px;    
}
.lds-ellipsis.small-dots.small-dots-day-list{
    left: 4px;    
    top: 60%;
}
.lds-ellipsis.small-dots.small-dots-adt{
    left: 10px;    
    top: 50%;
}

.lds-ellipsis.small-dots div {
    position: absolute;
    top: 0;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #3498db;
    animation: lds-ellipsis 1.2s cubic-bezier(0.37, 0.45, 0.57, 0.8) infinite;
}

.lds-ellipsis.small-dots div:nth-child(1) {
    left: 2px; /* Adjusted spacing */
}

.lds-ellipsis.small-dots div:nth-child(2) {
    left: 8.1px; /* Adjusted spacing */
}

.lds-ellipsis.small-dots div:nth-child(3) {
    left: 14px; /* Adjusted spacing */
}

@keyframes lds-ellipsis {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(8px, 0); /* Adjusted translation */
        opacity: 0;
    }
}