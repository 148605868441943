.totalCoTransferCard {
    width: 100%;
    padding: 10px;
    position: relative;

    .totalText {
        margin-top: -10px;
        margin-left: 12px;
        @media (max-width: 1280px) {
            font-size: 34px !important;
        }
    }
    .totalTextExpand{
        @media (max-width: 1280px) {
            font-size: 24px !important;
        }
    }
    .ofAvcCensus {
        @media only screen and (max-width : 1380px) {
            width: 38px;
            height: 32px;
        }
    }

    .totalTextMain {
        margin-top: -10px;
        width: 160px;
        height: 62px;
        border-radius: 10px;
        background: #eef3f7;
        display: flex;
        align-items: center;
        justify-content: center;

        @media only screen and (max-width : 1380px) {
            width: 130px;
            height: 50px;
            line-height: 50px;
        }
        @media only screen and (max-width : 1240px) {
            width: 80px;
            height: 50px;
            margin-top: 2px;
            line-height: 50px;
        }
    }

    .gradientText {
        font-weight: 700;
        font-size: 49px;
        line-height: 67px;
        background: linear-gradient(249.91deg, #4cebeb 28.23%, #076673 76.55%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @media only screen and (max-width : 1380px) {
            font-size: 36px;
            line-height: 50px;
        }
    }

    .gradientTextPercentage {
        font-weight: 700;
        font-size: 49px;
        line-height: 67px;
        background: linear-gradient(125.15deg, #eab80e -7.95%, #ffd399 96.28%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;

        @media only screen and (max-width : 1380px) {
            font-size: 36px;
            line-height: 50px;
        }
    }

    .gradientTextPercentage.skeleton {
        font-weight: 700;
        font-size: 49px;
        line-height: 67px;
        background: none;
        -webkit-text-fill-color: unset;
    }

    .percentageTransferWrap {
        background: #fff !important;
    }

    .percentageWrapper {
        background-color: #efefef;
        border-radius: 22px;
        padding: 1px 15px 3px 10px;
        margin-right: auto;
    }

    .leftSec {
        width: 50%;
        border-right: 1px solid #f1f1f4;
        color: #444652;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 85.84px
    }

    .rightSec {
        padding-left: 20px;
        width: 50%;
		@media only screen and (max-width : 1380px) {
			padding-left: 10px;
		}
    }

    .totalCoTransferCheckBox {
        overflow: auto;
        margin-left: -10px;

        &.selected {
            &:before {
                content: "";
                display: block;
                height: 16px;
                width: 16px;
                background-color: #4879f5;
                position: absolute;
                left: 18%;
                transform: translateX(-100%);
                border-radius: 50%;
                transition: all 0.2s;
            }
        }
    }
}

// skeleton styles

.totalCoTransferCard {
    .gradientText.skeleton {
        background: none;
    }
}